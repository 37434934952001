<template>
  <!-- login module -->
  <v-card width="400" class="mx-auto mt-5">
    <form @submit.prevent="auth">
      <v-card-title>
        <h1 class="display-1 text-center">Login</h1>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            color="accent"
            label="Username"
            prepend-icon="mdi-account-circle"
            v-model="username"
          />
          <v-text-field
            color="accent"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPassword = !showPassword"
            v-model="password"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" type="submit" dark>Login</v-btn>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
export default {
  name: 'LoginPage',
  data() {
    return {
      username: '',
      password: '',
      showPassword: false
    }
  },
  methods: {
    auth() {
      let data = 'username=' + this.username + '&' + 'password=' + this.password
      return this.$store
        .dispatch('auth/login', data)
        .then(() => {
          this.$router.push({ name: 'Home' })
        })
        .catch(error => {
          this.$store.dispatch('setLoader', false)
          const notification = {
            type: 'error',
            message: `Invalid Login or Password`
          }
          this.$store.dispatch('notification/add', notification, { root: true })
          console.log(error.response)
        })
    }
  }
}
</script>

<style scoped></style>
