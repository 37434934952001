import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto mt-5",attrs:{"width":"400"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.auth.apply(null, arguments)}}},[_c(VCardTitle,[_c('h1',{staticClass:"display-1 text-center"},[_vm._v("Login")])]),_c(VCardText,[_c(VForm,[_c(VTextField,{attrs:{"color":"accent","label":"Username","prepend-icon":"mdi-account-circle"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"color":"accent","type":_vm.showPassword ? 'text' : 'password',"label":"Password","prepend-icon":"mdi-lock","append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"accent","type":"submit","dark":""}},[_vm._v("Login")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }